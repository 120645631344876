<script lang="ts">
  import { link, useNavigate } from 'svelte-navigator';
  import TogetherLogo from '@/assets/svg/TogetherLogo.svelte';
  import { UserRole } from '@/interface/IUser';
  import { disconnectSocket, logout } from '@/lib/services/together/user.api';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  import LocaleSelector from '../locale-selector/LocaleSelector.svelte';

  const navigate = useNavigate();

  const handleLogout = async (): Promise<void> => {
    await logout();
    navigate('/');
  };

  $: isAuthorized =
    $userStore?.role === UserRole.SUPER_ADMIN ||
    $userStore?.role === UserRole.ADMIN ||
    $userStore?.role === UserRole.HOST ||
    $userStore?.role === UserRole.USER;
</script>

<nav
  class="relative z-10 flex flex-row items-center justify-between border-b border-gray-200 bg-white px-12 py-2 md:px-40"
>
  <a href="/" use:link>
    <TogetherLogo className="h-14 w-50 relative right-3 m-1" />
  </a>

  <div class="hidden w-fit items-center justify-center gap-2 md:flex xl:ps-40">
    {#if isAuthorized}
      <a
        class="rounded-md px-3 py-2 text-sm font-medium text-black transition duration-200 ease-in hover:bg-primary hover:text-white"
        href="/dashboard"
        use:link
        on:click={() => disconnectSocket()}
        >{@html $t('navbar.element-dashboard')}</a
      >
    {:else}
      <a
        class="rounded-md px-3 py-2 text-sm font-medium text-black transition duration-200 ease-in hover:bg-primary hover:text-white"
        href="/"
        use:link
        on:click={() => disconnectSocket()}
        data-cy="navbar-home-button">{@html $t('navbar.element-home')}</a
      >
      <a
        class="rounded-md px-3 py-2 text-sm font-medium text-black transition duration-200 ease-in hover:bg-primary hover:text-white"
        href="/integrations"
        use:link
        on:click={() => disconnectSocket()}
        data-cy="navbar-integrations-button"
        >{@html $t('navbar.element-integrations')}</a
      >
      <a
        class="rounded-md px-3 py-2 text-sm font-medium text-black transition duration-200 ease-in hover:bg-primary hover:text-white"
        href="/faq"
        use:link
        on:click={() => disconnectSocket()}
        data-cy="navbar-faq-button">{@html $t('navbar.element-faq')}</a
      >
    {/if}
  </div>
  <div class="flex w-72 items-center justify-end gap-3">
    {#if $userStore}
      <button
        class="rounded-md bg-primary px-3 py-2 text-sm font-medium text-white transition duration-200 ease-in hover:bg-primary/50 hover:text-black"
        on:click={handleLogout}>{@html $t('navbar.element-logout')}</button
      >
    {:else}
      <div class="flex items-center justify-center gap-3">
        <a
          class="w-full whitespace-nowrap rounded-md px-2 py-2 text-center text-sm font-medium text-black transition duration-200 ease-in hover:bg-primary hover:text-white"
          href="/login"
          use:link
          on:click={() => disconnectSocket()}
          >{@html $t('navbar.element-login')}</a
        >
        <a
          id="signup-button"
          class="w-full whitespace-nowrap rounded-md bg-primary px-2 py-2 text-sm font-medium text-white transition duration-100 ease-in hover:bg-primary/70 hover:text-black"
          href="/signup"
        >
          {@html $t('navbar.element-signup')}
        </a>
      </div>
    {/if}
    <LocaleSelector navbarPlacement />
  </div>
</nav>
