import { get } from 'svelte/store';
import type { ISession } from '@/interface/ISession';
import { ToastNotification } from '@/lib/services/toast';
import {
  cancelSession,
  finishSession,
} from '@/lib/services/together/together.api';
import { t } from '@/locales/i18n';

import { openModal } from '../../modal/modal.store';
import ConfirmationModal from '../../modal/modals/ConfirmationModal.svelte';
import UpdateSessionModal from '../../modal/modals/UpdateSessionModal.svelte';

export const handleEditSessionModal = (
  session: ISession,
  onSubmit?: () => void,
) => {
  openModal(UpdateSessionModal, {
    allowCloseClick: false,
    props: {
      session,
      onSubmit: () => onSubmit?.(),
    },
  });
};
export const handleFinishSession = async (
  session: ISession,
  onSubmit?: () => void,
) => {
  const finishCurrentSession = async () => {
    try {
      await finishSession(session.id);
      ToastNotification.success(get(t)('dashboard.sessions.finish-success'));
      onSubmit?.();
    } catch (error) {
      ToastNotification.error(get(t)('dashboard.sessions.finish-error'));
      console.error(error);
    }
  };

  openModal(ConfirmationModal, {
    allowCloseClick: false,
    props: {
      text: get(t)('dashboard.sessions.finish-title'),
      onSubmit: () => finishCurrentSession(),
    },
  });
};

export const handleCancelSession = async (
  session: ISession,
  onSubmit?: () => void,
) => {
  const cancelCurrentSession = async () => {
    try {
      await cancelSession(session.id);
      ToastNotification.success(get(t)('dashboard.sessions.cancel-success'));
      onSubmit?.();
    } catch (error) {
      ToastNotification.error(get(t)('dashboard.sessions.cancel-error'));
      console.error(error);
    }
  };

  openModal(ConfirmationModal, {
    allowCloseClick: false,
    props: {
      text: get(t)('dashboard.sessions.cancel-title'),
      onSubmit: () => cancelCurrentSession(),
    },
  });
};
