<script>
  import UserIcon from '@/lib/components/icons/UserIcon.svelte';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';
</script>

<div
  class="flex w-fit flex-row items-center justify-center gap-2 hover:cursor-pointer hover:text-primary"
  on:click={() => {
    window.location.href = '/dashboard/settings/profile';
  }}
  on:keypress
>
  {#if $userStore?.avatarUrl}
    <img
      src={$userStore?.avatarUrl}
      alt="avatar thumbnail"
      class="h-12 w-12 rounded-full object-cover hover:cursor-pointer"
    />
  {:else}
    <UserIcon style="text-grey w-12 h-12" />
  {/if}
  <div class="flex w-fit flex-col">
    <span class="font-bold text-grey">{$t('dashboard.sidebar.welcome')}</span>
    <span class="text-sm font-bold"
      >{$userStore?.name} {$userStore?.lastName}</span
    >
  </div>
</div>
