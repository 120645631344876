<script lang="ts">
  export let color:
    | 'blue'
    | 'red'
    | 'green'
    | 'yellow'
    | 'indigo'
    | 'purple'
    | 'pink'
    | 'default' = 'default';
  export let text = '';

  const colors = {
    blue: 'bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded',
    gray: 'bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded',
    red: 'bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded',
    green:
      'bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded',
    yellow:
      'bg-yellow-100 text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded',
    indigo:
      'bg-indigo-100 text-indigo-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded',
    purple:
      'bg-purple-100 text-purple-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded',
    pink: 'bg-pink-100 text-pink-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded',
    default:
      'bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded',
  };

  $: classStyle = `${colors[color] || colors.default}`;
</script>

<span class={classStyle}>{text}</span>
