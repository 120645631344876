<script lang="ts">
  import { onMount, type ComponentProps } from 'svelte';
  import { navigate, useLocation } from 'svelte-navigator';
  import { get } from 'svelte/store';
  import Nut from '@/assets/svg/NutIcon.svelte';
  import type NutIcon from '@/assets/svg/NutIcon.svelte';
  import TogetherLogo from '@/assets/svg/TogetherLogo.svelte';
  import { SubscriptionPlan, type IExperience } from '@/interface/IExperience';
  import type { ISessionSchedule } from '@/interface/ISessionSchedule';
  import { UserRole } from '@/interface/IUser';
  import {
    getSessions,
    getSessionSchedule,
  } from '@/lib/services/together/together.api';
  import { logout } from '@/lib/services/together/user.api';
  import { locale, t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  import Button from '../button/Button.svelte';
  import SidebarMenuButton from '../buttons-group/buttons/SidebarMenuButton.svelte';
  import WelcomeUserCard from '../cards/WelcomeUserCard.svelte';
  import ExperienceIcon from '../icons/ExperienceIcon.svelte';
  import LogoutIcon from '../icons/LogoutIcon.svelte';
  import PersonIcon from '../icons/PersonIcon.svelte';
  import UsersIcon from '../icons/UsersIcon.svelte';
  import TenantSelector from '../tenant-selector/TenantSelector.svelte';
  import { sessionCountStore } from './session.count.store';

  let isHost = $userStore?.role === UserRole.HOST;
  let isAdmin =
    $userStore?.role === UserRole.ADMIN ||
    $userStore?.role === UserRole.SUPER_ADMIN;
  let isUser = $userStore?.role === UserRole.USER;

  const location = useLocation();

  let dashboardButtons: (ComponentProps<SidebarMenuButton> & {
    icon: typeof PersonIcon | typeof ExperienceIcon | typeof UsersIcon;
    name: string;
    url: string;
  })[] = [
    {
      name: 'users',
      text: $t('dashboard.sidebar.users'),
      icon: PersonIcon,
      url: '/dashboard/users',
      selected: isAdmin,
    },
    {
      name: 'teams',
      text: $t('dashboard.sidebar.teams'),
      icon: UsersIcon,
      url: '/dashboard/teams',
    },
    {
      name: 'experiences',
      text: $t('dashboard.sidebar.experiences'),
      icon: ExperienceIcon,
      url: '/dashboard/experiences',
      selected: isHost || isUser,
    },
    {
      name: 'sessions',
      text: $t('dashboard.sidebar.sessions'),
      icon: UsersIcon,
      url: '/dashboard/sessions',
    },
  ];

  let bottomSidebarButtons: (ComponentProps<SidebarMenuButton> & {
    icon: typeof NutIcon;
    name: string;
    url: string;
  })[] = [
    {
      name: 'settings',
      text: $t('dashboard.sidebar.settings'),
      icon: Nut,
      url: '/dashboard/settings',
    },
  ];

  $: dashboardButtons = isUser
    ? dashboardButtons.filter(
        (button) =>
          button.text === $t('dashboard.sidebar.experiences') ||
          button.text === $t('dashboard.sidebar.sessions'),
      )
    : dashboardButtons;

  $: dashboardButtons = isHost
    ? dashboardButtons.filter(
        (button) =>
          button.text === $t('dashboard.sidebar.teams') ||
          button.text === $t('dashboard.sidebar.experiences') ||
          button.text === $t('dashboard.sidebar.sessions'),
      )
    : dashboardButtons;

  $: $locale,
    (dashboardButtons = dashboardButtons.map((button) => ({
      ...button,
      text: $t(`dashboard.sidebar.${button.name}`),
    }))),
    (bottomSidebarButtons = bottomSidebarButtons.map((button) => ({
      ...button,
      text: $t(`dashboard.sidebar.${button.name}`),
    })));

  $: $location,
    ((bottomSidebarButtons = bottomSidebarButtons.map((button) => ({
      ...button,
      selected: get(location).pathname.includes(button.url),
    }))),
    (dashboardButtons = dashboardButtons.map((button) => ({
      ...button,
      selected: get(location).pathname.includes(button.url),
    }))));

  const handleNavigation = (url: string) => {
    navigate(url);
  };

  const handleLogout = async (): Promise<void> => {
    await logout();
    navigate('/');
  };

  let currentAmountOfSessions = 0;
  let trialExperience: IExperience;

  $: {
    currentAmountOfSessions = $sessionCountStore;
  }

  let freeTrialSchedule: ISessionSchedule | undefined;

  onMount(async () => {
    const { count } = await getSessions();
    $sessionCountStore = count;
    if (count > 3) {
      currentAmountOfSessions = $sessionCountStore;
    } else {
      freeTrialSchedule = await getSessionSchedule('Free Trial');
      currentAmountOfSessions = count;
    }
  });

  $: {
    if (freeTrialSchedule) {
      trialExperience =
        $locale === 'en'
          ? freeTrialSchedule.englishExperience
          : freeTrialSchedule.spanishExperience;
    }
  }

  const handleTrialExperience = () => {
    navigate(`/dashboard/experiences/${trialExperience.id}`);
  };
</script>

<div
  class="z-30 flex w-80 flex-col justify-between border-r border-light-grey bg-white py-4"
>
  <div class="flex w-full flex-col items-center justify-center gap-10 pt-4">
    <TogetherLogo className="h-16" />
    <WelcomeUserCard />
    <div class="flex w-full flex-col items-start justify-center">
      {#each dashboardButtons as button}
        <SidebarMenuButton
          text={button.text}
          selected={button.selected}
          on:click={() => handleNavigation(button.url)}
        >
          <svelte:component this={button.icon} />
        </SidebarMenuButton>
      {/each}

      {#if (isAdmin || isHost) && $userStore?.tenant.subscriptionPlan === SubscriptionPlan.FREE && trialExperience}
        <div
          class="mt-4 flex w-full flex-col items-center justify-center gap-2"
        >
          <Button
            disabled={currentAmountOfSessions === 3}
            on:click={handleTrialExperience}
            testId="use-free-trial-session"
            ><span>{$t('use-trial-session-button')}</span></Button
          >
          <span class="text-xl font-bold text-grey"
            >{currentAmountOfSessions}/3</span
          >
        </div>
      {/if}
    </div>
  </div>
  <div class="flex w-full flex-col gap-4">
    {#if $userStore?.role === UserRole.SUPER_ADMIN}
      <TenantSelector />
    {/if}
    <div class="flex w-full flex-col items-start justify-center">
      {#each bottomSidebarButtons as button}
        <SidebarMenuButton
          text={button.text}
          selected={button.selected}
          on:click={() => handleNavigation(button.url)}
        >
          <svelte:component this={button.icon} />
        </SidebarMenuButton>
      {/each}
    </div>
    <div
      class="flex w-full flex-row items-center gap-4 px-10 font-bold text-dark-grey hover:cursor-pointer hover:text-primary"
    >
      <LogoutIcon style="h-5 w-5 rotate-90" />
      <span class="font-semibold" on:keypress on:click={handleLogout}>
        {$t('dashboard.sidebar.logout')}
      </span>
    </div>
  </div>
</div>
