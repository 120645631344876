<script lang="ts">
  import { navigate } from 'svelte-navigator';
  import type { IExperience } from '@/interface/IExperience';
  import type { ITeam } from '@/interface/ITeam';
  import type { IUser } from '@/interface/IUser';
  import SearchInput from '@/lib/components/search-input/SearchInput.svelte';
  import { ToastNotification } from '@/lib/services/toast';
  import {
    createSession,
    getTeams,
    getTenantUsers,
  } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  import Button from '../../button/Button.svelte';
  import XIcon from '../../icons/XIcon.svelte';
  import Input from '../../input/Input.svelte';
  import InviteUserList from '../../lists/InviteUserList.svelte';
  import Loading from '../../loading/Loading.svelte';
  import { closeModal } from '../modal.store';

  export let onSubmit: (() => void) | undefined = undefined;
  export let experience: IExperience;
  let selectedUserIds = new Set<IUser['id']>();

  let sessionName = '';
  let scheduledDate = '';
  let isCreatingSession = false;
  let tenantUsers: IUser[] = [];
  let filteredUsers: IUser[] = [];

  let selectedTeam: ITeam | undefined = undefined;
  let teamOptions: ITeam[] = [];

  const fetchTeamOptions = async () => {
    try {
      const { teams } = await getTeams({
        language: experience.language,
        limit: 100,
        offset: 0,
      });

      teamOptions = teams;
      return teams;
    } catch (error) {
      console.error(error);
      ToastNotification.error(
        $t('dashboard.experiences.start-session-fetch-teams-error'),
      );
      return [];
    }
  };

  const getUsers = async () => {
    try {
      const { users } = await getTenantUsers();
      tenantUsers = users;

      filteredUsers = tenantUsers;

      return filteredUsers;
    } catch (error) {
      console.error(error);
      ToastNotification.error(
        $t('dashboard.experiences.start-session-fetch-users-error'),
      );
      return [];
    }
  };

  const handleConfirm = async () => {
    isCreatingSession = true;
    try {
      await createSession(
        experience.id,
        $userStore!.id,
        [...selectedUserIds],
        sessionName,
        scheduledDate,
        selectedTeam ? selectedTeam.id : undefined,
      );
      closeModal();
      ToastNotification.success(
        $t('dashboard.experiences.start-session-success'),
      );
      onSubmit?.();
      navigate(`/dashboard/sessions`);
    } catch (error) {
      ToastNotification.error($t('dashboard.experiences.start-session-error'));
    } finally {
      isCreatingSession = false;
    }
  };

  const handleSelect = (user: IUser) => {
    selectedUserIds.has(user.id)
      ? selectedUserIds.delete(user.id)
      : selectedUserIds.add(user.id);
    selectedUserIds = new Set(selectedUserIds);
  };

  const handleSearch = (event: CustomEvent<any>) => {
    const { searchBy, searchQuery } = event.detail;
    filteredUsers = tenantUsers.filter((user) =>
      searchBy === 'name'
        ? `${user.name} ${user.lastName}`
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        : user.email.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  };

  $: buttonDisabled =
    selectedUserIds.size < 2 ||
    selectedUserIds.size > 30 ||
    sessionName.trim() === '' ||
    !sessionName.trim().match(/^[a-zA-Z0-9 ]+$/) ||
    scheduledDate === '';

  const handleTeamChange = (e: Event) => {
    const { value: teamId } = e.target as HTMLInputElement;
    if (teamId === '') {
      selectedTeam = undefined;
      selectedUserIds = new Set();
      sessionName = '';
      return;
    }
    const team = teamOptions.find((team) => team.id === +teamId!);
    if (!team) {
      selectedUserIds = new Set();
      sessionName = '';
      return;
    }
    selectedTeam = team;
    selectedUserIds = new Set(selectedTeam?.users.map((user) => user.id) ?? []);
    sessionName = selectedTeam?.name ?? '';
  };

  const parseTeamOptions = (teams: ITeam[]) => [
    {
      label: $t('dashboard.experiences.start-session-select-team-option-label'),
      value: '',
    },
    ...teams.map((team) => ({ label: team.name, value: String(team.id) })),
  ];

  const lockTeamMembers = (user: IUser) => {
    if (selectedTeam) {
      return !!selectedTeam.users.find((member) => member.id === user.id);
    }
    return false;
  };
</script>

<div
  class="flex w-fit flex-col items-center justify-between gap-6 rounded-lg bg-white p-4"
>
  <div class="flex w-full flex-col gap-4">
    <div class="flex w-full flex-row gap-1">
      <div class="flex w-full flex-col gap-1">
        <span class="font-baloo font-bold"
          >{$t('dashboard.experiences.start-session-title')}</span
        >
        <span class="text-sm text-grey">
          {$t('dashboard.experiences.start-session-subtitle')}
        </span>
      </div>
      <div class="h-full">
        <button on:click={closeModal}>
          <XIcon
            style="w-6 h-6 hover:bg-primary hover:text-white rounded-full"
          />
        </button>
      </div>
    </div>
    {#await getUsers()}
      <Loading loadingSize="h-20 w-20" />
    {:then users}
      {#if users}
        <div class="flex w-full flex-row gap-4">
          <SearchInput
            searchByOptions={['name', 'email']}
            on:search={handleSearch}
          />
          {#await fetchTeamOptions()}
            <Loading loadingSize="h-10 w-10" />
          {:then teams}
            <div class="flex w-full flex-row items-center justify-end gap-2">
              <span class="font-bold"
                >{$t(
                  'dashboard.experiences.start-session-select-team-label',
                )}</span
              >
              <Input
                testId="team-select"
                on:change={handleTeamChange}
                options={parseTeamOptions(teams)}
                styleClass="w-1/2"
                type="select"
              />
            </div>
          {/await}
        </div>

        <InviteUserList
          users={filteredUsers}
          selectedUserIds={selectedUserIds}
          onSelect={handleSelect}
          disableCondition={lockTeamMembers}
        />
        {#if selectedUserIds && selectedUserIds.size > 30}
          <p class="text-red-700">
            {$t('dashboard.experiences.start-session-max-users-error')}
          </p>
        {:else if selectedUserIds.size < 2}
          <p class="text-red-700">
            {$t('dashboard.experiences.start-session-min-users-error')}
          </p>
        {/if}
      {:else}
        <p>{$t('dashboard.experiences.start-session-no-users')}</p>
      {/if}
    {/await}
  </div>
  <div class="flex h-full w-full flex-row gap-4 self-start">
    <Input
      testId="session-name-input"
      bind:value={sessionName}
      label={$t('dashboard.experiences.start-session-name-input-label')}
      placeholder={$t(
        'dashboard.experiences.start-session-name-input-placeholder',
      )}
      error={!sessionName.trim().match(/^[a-zA-Z0-9 ]+$/)
        ? $t('dashboard.experiences.start-session-name-input-error')
        : ''}
    />
    <Input
      testId="session-date-input"
      bind:value={scheduledDate}
      label={$t('dashboard.experiences.start-session-date-input-label')}
      type="date"
      minDate={new Date().toISOString().slice(0, 10)}
    />
  </div>
  <div class="flex w-full flex-row items-center justify-end">
    {#if !isCreatingSession}
      <Button
        disabled={buttonDisabled}
        testId="start-session-confirm-button"
        on:click={handleConfirm}
      >
        <span>{$t('dashboard.experiences.start-session-confirm-button')}</span>
      </Button>
    {:else}
      <Loading loadingSize="h-8 w-8" />
    {/if}
  </div>
</div>
