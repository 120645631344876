<script lang="ts">
  export let title: string;
  export let price: string;
  export let priceLabel: string;
  export let descriptions: string[];
  export let buttonLabel: string;
  export let buttonDisabled = false;
  export let isPrimary = false;
  export let buttonTestId = '';
  export let onClick: () => void;
</script>

<div
  class={`flex min-h-[34rem] w-[18rem] flex-col justify-between gap-3 rounded-lg border-2 ${
    isPrimary
      ? 'border-primary bg-primary text-white'
      : 'border-light-grey bg-white'
  } p-8`}
>
  <div>
    <div class={`pb-8`}>
      <h2 class="text-center text-3xl font-bold">{title}</h2>
      <h3 class="mt-4 text-center text-5xl font-bold">
        {price} <span class="text-sm">{priceLabel}</span>
      </h3>
    </div>
    <ul
      class={`border-y py-8 ${isPrimary ? 'border-white/50' : 'border-gray-700'}`}
    >
      {#each descriptions as description}
        <li>{description}</li>
      {/each}
    </ul>
  </div>

  <button
    data-cy={buttonTestId}
    class={`relative rounded px-4 py-2 transition duration-200 ease-in ${
      isPrimary
        ? 'bg-white text-primary hover:bg-white/50'
        : 'bg-primary text-white hover:bg-primary/50'
    } ${buttonDisabled ? 'cursor-not-allowed opacity-50' : ''}`}
    disabled={buttonDisabled}
    on:click={onClick}
  >
    {buttonLabel}
  </button>
</div>
