<script lang="ts">
  import type { ITeam } from '@/interface/ITeam';
  import { t } from '@/locales/i18n';

  import Button from '../button/Button.svelte';

  export let teams: ITeam[];

  export let actions: {
    name: string;
    onAction: (team: ITeam) => void;
    disabledCondition?: (team: ITeam) => boolean;
  }[] = [];
</script>

<table class="w-full text-left text-sm text-dark-grey">
  <thead>
    <tr class="h-10">
      <th scope="col"><span>{$t('dashboard.teams.table-name')}</span></th>
      <th scope="col"><span>{$t('dashboard.teams.table-host')}</span></th>
      <th scope="col"><span>{$t('dashboard.teams.table-members')}</span></th>
      <th scope="col"
        ><span>{$t('dashboard.teams.table-monthly-sessions')}</span></th
      >
      {#if actions.length > 0}
        <th scope="col"><span>{$t('dashboard.teams.table-actions')}</span></th>
      {/if}
    </tr>
  </thead>
  <tbody>
    {#each teams as team, rowIndex}
      <tr class=" h-16">
        <td>
          <span>{team.name}</span>
        </td>
        <td><span>{team.host.name} {team.host.lastName}</span></td>
        <td><span>{team.users.length}</span></td>
        <td
          ><span
            >{team.monthlySession
              ? $t('dashboard.teams.view.monthly-sessions.enabled')
              : $t('dashboard.teams.view.monthly-sessions.disabled')}</span
          ></td
        >

        <td>
          <div class="flex flex-row gap-2">
            {#each actions as { name, onAction, disabledCondition }}
              <Button
                disabled={disabledCondition?.(team) ?? false}
                testId={`${name}-button-${rowIndex}`}
                on:click={() => onAction(team)}
              >
                <span>{name}</span>
              </Button>
            {/each}
          </div>
        </td>
      </tr>
    {/each}
  </tbody>
</table>
