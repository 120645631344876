<script>
  import TogetherLogo from '@/assets/svg/TogetherLogo.svelte';
  import { t } from '@/locales/i18n';
</script>

<div
  class="flex flex-row items-center justify-between bg-brand-bg p-8"
  data-cy="footer-container"
>
  <TogetherLogo className="h-10 md:h-14 w-64 relative right-10" />

  <div class="flex w-fit flex-col items-center justify-center gap-4">
    <p class="text-xs text-primary md:text-sm">
      {@html $t('footer.copyright')}
    </p>
  </div>

  <ul
    class="flex w-64 flex-col items-end justify-center gap-1 text-sm text-primary"
  >
    <li>
      <a href="/terms" data-cy="footer-terms-link">{@html $t('footer.terms')}</a
      >
    </li>
    <li>
      <a href="/privacy" data-cy="footer-privacy-link"
        >{@html $t('footer.privacy')}</a
      >
    </li>
    <li>
      <a href="/support" data-cy="footer-support-link"
        >{@html $t('footer.support')}</a
      >
    </li>
    <li>
      <a href="/faq" data-cy="footer-faq-link">{@html $t('footer.faq')}</a>
    </li>
  </ul>
</div>
