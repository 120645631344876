import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserSession,
  type ICognitoUserSessionData,
} from 'amazon-cognito-identity-js';
import { get } from 'svelte/store';
import type { IUser } from '@/interface/IUser';
import Api, {
  del,
  get as getApi,
  patch,
  post,
  put,
} from '@/lib/services/axios/api-axios';
import { refreshUserToken } from '@/lib/services/cognito';
import { userPoolStore, userSessionStore } from '@/store/cognitoStore';
import { guestSocketSession, hostSocketSession } from '@/store/socketSession';
import { userStore } from '@/store/userStore';

export const signUp = async (signUpPayload: any) => {
  await post('/auth/signup', signUpPayload);
};
export const login = async (
  email: string,
  password: string,
): Promise<{ newPasswordRequired: boolean }> => {
  const result = await post<{ newPasswordRequired: boolean | undefined }>(
    '/auth/login',
    {
      email,
      password,
      webType: 'couples',
    },
  );
  if (result.newPasswordRequired) {
    return { newPasswordRequired: true };
  }

  await initSession(result);

  return { newPasswordRequired: false };
};

export const generateNewPassword = async (
  email: string,
  newPassword: string,
) => {
  await post('/auth/change-password', {
    email,
    newPassword,
  });
};

export const getProfile = async () => {
  const user = await getApi<IUser>('/auth/profile');
  userStore.set(user);
};

export const logout = async () => {
  Api.defaults.headers.common['Authorization'] = '';
  const currentUser = get(userPoolStore).getCurrentUser();
  currentUser?.signOut();
  userStore.set(null);
  userSessionStore.set(null);
  disconnectSocket();
};

export const initSession = async (authResult: any) => {
  if ((window as any).Cypress) {
    await getProfile();
    return;
  }

  const idToken = new CognitoIdToken({
    IdToken: authResult.idToken.jwtToken,
  });
  const payload = idToken.decodePayload();

  const accessToken = new CognitoAccessToken({
    AccessToken: authResult.accessToken.jwtToken,
  });
  Api.defaults.headers.common['Authorization'] =
    `Bearer ${authResult.accessToken.jwtToken}`;

  const refreshToken = new CognitoRefreshToken({
    RefreshToken: authResult.refreshToken.token,
  });

  const session = new CognitoUserSession({
    AccessToken: accessToken,
    IdToken: idToken,
    RefreshToken: refreshToken,
  } as ICognitoUserSessionData);

  const cognitoUser = new CognitoUser({
    Username: payload['email'],
    Pool: get(userPoolStore),
  });
  await getProfile();
  cognitoUser.setSignInUserSession(session);
  userSessionStore.set(session);
};

export const refreshSession = async () => {
  try {
    if ((window as any).Cypress) {
      await getProfile();
      return;
    }
    const cognitoUserSession = await refreshUserToken(get(userPoolStore));
    const accessToken = cognitoUserSession.getAccessToken().getJwtToken();

    Api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    await getProfile();
  } catch (error) {
    console.error(error);
  }
};

export const exchangeOAuthCode = async (code: string) => {
  return post('/auth/exchange-oauth-code', { code });
};

export const unlinkWorkspace = async () => {
  return patch('/auth/unlink-workspace');
};

export const disconnectSocket = () => {
  get(hostSocketSession)?.disconnect();
  get(guestSocketSession)?.disconnect();
  hostSocketSession.set(null);
  guestSocketSession.set(null);
};

export const resendUserCredentials = async (user: IUser): Promise<void> => {
  return await post('/auth/resend-credentials', { email: user.email });
};

export const sendRecoveryCode = async (email: string): Promise<void> => {
  return await post('/auth/forgot-password/send-recovery-code', { email });
};

export const confirmRecovery = async (
  email: string,
  code: string,
  newPassword: string,
): Promise<void> => {
  return await post('/auth/forgot-password/confirm-recovery', {
    email,
    code,
    newPassword,
  });
};

export const deleteUser = async (user: IUser): Promise<void> => {
  return await del(`/user/${user.id}`);
};

export const changeTenant = async (tenantId: number): Promise<void> => {
  return put('/user/change-tenant', {
    tenantId: tenantId,
  });
};

export const updateUserProfile = async (
  userProfilePayload: Partial<IUser>,
): Promise<IUser> => {
  return await patch<IUser>('/user/profile', userProfilePayload);
};
