<script lang="ts">
  import moment from 'moment';
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-navigator';
  import type { ICategory } from '@/interface/ICategory';
  import {
    ExperienceLanguages,
    SubscriptionPlan,
    type IExperience,
  } from '@/interface/IExperience';
  import type { ISessionSchedule } from '@/interface/ISessionSchedule';
  import ExperienceCard from '@/lib/components/cards/ExperienceCard.svelte';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import Paginator from '@/lib/components/paginator/Paginator.svelte';
  import {
    getAllCategories,
    getExperiences,
    getSessionSchedule,
    type IExperienceQuery,
  } from '@/lib/services/together/together.api';
  import { locale, t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  let query: IExperienceQuery = {
    limit: 4,
    offset: 0,
    categoryId: undefined,
    language:
      $locale === 'en'
        ? ExperienceLanguages.english
        : ExperienceLanguages.spanish,
  };

  let sessionEvent: ISessionSchedule | undefined = undefined;
  let monthlyExperience: IExperience | undefined | null = undefined;

  let categories: ICategory[] = [];

  let experiencesPromise: Promise<{
    experiences: IExperience[];
    count: number;
  }>;

  let selectedLanguage: string =
    $locale === 'en'
      ? ExperienceLanguages.english
      : ExperienceLanguages.spanish;

  const fetchExperiences = () => {
    experiencesPromise = getExperiences(query);
  };
  $: query, fetchExperiences();

  const handleExperienceDetails = (experience: IExperience) => {
    navigate(`/dashboard/experiences/${experience.id}`);
  };

  const blockUser = (experience: IExperience) => {
    const isMonthly = experience.id === monthlyExperience?.id;

    $userStore?.tenant.subscriptionPlan === SubscriptionPlan.STARTER &&
      isMonthly;

    if ($userStore?.tenant.subscriptionPlan === SubscriptionPlan.FREE) {
      return true;
    }

    if (
      $userStore?.tenant.subscriptionPlan === SubscriptionPlan.STARTER &&
      isMonthly
    ) {
      return false;
    }
    if ($userStore?.tenant.subscriptionPlan === SubscriptionPlan.PRO) {
      return false;
    }
    return true;
  };

  const handleLanguageChange = (language: string) => {
    query = { ...query, offset: 0, language: language as ExperienceLanguages };
    monthlyExperience =
      language === ExperienceLanguages.english
        ? sessionEvent?.englishExperience
        : sessionEvent?.spanishExperience;
  };

  const handleCategoryChange = (categoryId: number) => {
    query = {
      ...query,
      offset: 0,
      categoryId: categoryId === 0 ? undefined : categoryId,
    };
  };

  onMount(async () => {
    const result = await getAllCategories();
    categories = result.categories;
    try {
      const thisMonth = moment().format('MMMM');
      sessionEvent = await getSessionSchedule(thisMonth);
      monthlyExperience =
        $locale === 'en'
          ? sessionEvent.englishExperience
          : sessionEvent.spanishExperience;
    } catch (error) {
      monthlyExperience = null;
    }
  });
</script>

<div class="flex h-full w-full flex-col">
  <div
    class="flex h-16 w-full flex-row items-center justify-start border-b border-light-grey p-6"
  >
    <h1 class="mt-1 text-xl font-bold text-primary">
      {$t('dashboard.experiences.title')}
    </h1>
  </div>
  <div class="flex h-16 w-full flex-row items-center justify-start gap-4 p-6">
    <div class="flex flex-row items-center">
      <label
        for="language"
        class="text-md me-3 whitespace-nowrap font-medium text-dark-grey"
        >{$t('dashboard.experiences.language-label')}</label
      >
      <select
        name="language"
        class="h-8 w-full pl-2 pr-8 text-sm capitalize text-gray-700"
        data-cy="experience-language-selector"
        on:change={(e) => handleLanguageChange(e.currentTarget.value)}
      >
        {#each Object.values(ExperienceLanguages) as language}
          <option value={language} selected={language === selectedLanguage}
            >{language === 'English' ? 'English' : 'Español'}</option
          >
        {/each}
      </select>
    </div>
    <div class="flex flex-row items-center justify-center">
      <label
        for="category-filter"
        class="text-md me-3 whitespace-nowrap font-medium text-dark-grey"
        >{$t('dashboard.experiences.category')}</label
      >
      <select
        name="category-filter"
        class="h-8 w-full pl-2 pr-8 text-sm capitalize text-gray-700"
        data-cy="category-filter"
        on:change={(e) => handleCategoryChange(+e.currentTarget.value)}
      >
        <option value={0}>{$locale === 'en' ? 'All' : 'Todas'}</option>
        {#each categories as category}
          <option value={category.id}
            >{$locale === 'en'
              ? category.englishName
              : category.spanishName}</option
          >
        {/each}
      </select>
    </div>
  </div>
  <div class="flex w-full flex-row gap-4 p-6">
    <div class="h-[68rem] max-w-[50rem]">
      {#await experiencesPromise}
        <div class="flex h-[30rem] w-[50rem] grow items-center justify-center">
          <Loading loadingSize="h-32 w-32" />
        </div>
      {:then { experiences, count }}
        {#if count === 0}
          <div
            class="flex h-[35rem] w-[50rem] grow items-center justify-center"
          >
            <span class="text-dark-grey"
              >{$t('dashboard.experiences.no-experiences')}</span
            >
          </div>
        {:else}
          <div class="flex w-full flex-wrap gap-4">
            {#if monthlyExperience !== undefined}
              {#each experiences as experience}
                <ExperienceCard
                  blockUser={blockUser(experience)}
                  experience={experience}
                  startSession={() => handleExperienceDetails(experience)}
                />
              {/each}
            {/if}
          </div>
          <div class="p-4">
            <Paginator
              total={count}
              query={query}
              onChange={(newQuery) => {
                query = { ...query, ...newQuery };
              }}
            />
          </div>
        {/if}
      {:catch error}
        <p class="text-center" data-cy="error-message">
          {$t('dashboard.experiences.fetch-error', { error: error.message })}
        </p>
      {/await}
    </div>
    {#if monthlyExperience}
      <div class="w-fit">
        <ExperienceCard
          experience={monthlyExperience}
          monthlyExperience={true}
          blockUser={blockUser(monthlyExperience)}
          startSession={() =>
            monthlyExperience && handleExperienceDetails(monthlyExperience)}
        />
      </div>
    {/if}
  </div>
</div>
