<script lang="ts">
  import { validator } from '@felte/validator-yup';
  import { createForm } from 'felte';
  import { navigate } from 'svelte-navigator';
  import * as yup from 'yup';
  import type { IWorkspaceUser } from '@/interface/IWorkspaceUser';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import SearchUserInput from '@/lib/components/search-input/SearchInput.svelte';
  import InviteUsersTable from '@/lib/components/tables/InviteUsersTable.svelte';
  import { get, post } from '@/lib/services/axios/api-axios';
  import { ToastNotification } from '@/lib/services/toast';
  import { getTenantUsers } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  import Button from '../../button/Button.svelte';
  import DashIcon from '../../icons/DashIcon.svelte';
  import PlusIcon from '../../icons/PlusIcon.svelte';
  import Input from '../../input/Input.svelte';
  import { closeModal, openModal } from '../modal.store';
  import ConfirmationModal from './ConfirmationModal.svelte';

  const userSchema = yup.object({
    email: yup
      .string()
      .required('Email is required')
      .email('Email is not valid'),
    name: yup.string().required('Name is required'),
    lastName: yup.string().required('Lastname is required'),
  });

  const formSchema = yup
    .object()
    .shape({ users: yup.array().of(userSchema) })
    .required();

  const initialValues = { users: [{ email: '', name: '', lastName: '' }] };

  const hasExceededSeatLimit = async () => {
    const currentLimit = $userStore?.tenant.seats || 0;

    const { count } = await getTenantUsers();

    return count >= currentLimit;
  };

  const showUpgradeModal = async (onSubmit?: () => void) => {
    openModal(ConfirmationModal, {
      allowCloseClick: false,
      props: {
        title: $t('dashboard.users.upgrade-plan-modal-title'),
        text: $t('dashboard.users.upgrade-plan-modal-text'),
        onSubmit: () => {
          onSubmit?.();
          navigate('/dashboard/settings/billing');
        },
      },
    });
  };

  const {
    form,
    errors,
    isSubmitting,
    handleSubmit,
    reset,
    addField,
    unsetField,
    data: formValues,
  } = createForm({
    initialValues,
    extend: [validator({ schema: formSchema })],
    onSubmit: async (formInputs) => {
      if (await hasExceededSeatLimit()) {
        showUpgradeModal(closeModal);
        return;
      }
      try {
        await Promise.all(
          formInputs.users.map(
            async (user) => await post('/auth/invite-user', user),
          ),
        );

        reset();
        const message =
          formInputs.users.length > 1
            ? 'Users invited successfully! an email with credentials has been sent to each user'
            : 'User invited successfully!, an email with credentials has been sent';
        ToastNotification.success(message);
      } catch (error) {
        console.error(error);
        ToastNotification.error('Error inviting user');
      }
    },
  });

  let tenant = $userStore?.tenant;
  let workspaceUsers: IWorkspaceUser[] = [];
  let filteredUsers: IWorkspaceUser[] = [];

  const fetchWorkspaceUsers = async () => {
    try {
      workspaceUsers = await get<IWorkspaceUser[]>('/auth/workspace-users');

      filteredUsers = workspaceUsers;

      return filteredUsers;
    } catch (error) {
      console.error(error);
      ToastNotification.error($t('dashboard.users.invite-users.get-error'));
      return [];
    }
  };

  const handleInviteSlackUser = async (
    name: string,
    lastName: string,
    email: string,
  ) => {
    if (await hasExceededSeatLimit()) {
      showUpgradeModal(closeModal);
      return;
    }
    try {
      await post('/auth/invite-workspace-user', {
        name,
        lastName,
        email,
      });
      ToastNotification.success(
        $t('dashboard.users.invite-users.invite-success'),
      );
      filteredUsers = await fetchWorkspaceUsers();
    } catch (error) {
      console.error(error);
      ToastNotification.error($t('dashboard.users.invite-users.invite-error'));
    }
  };

  const handleSearch = (event: CustomEvent<any>) => {
    const { searchBy, searchQuery } = event.detail;
    filteredUsers = workspaceUsers.filter((user) =>
      searchBy === 'name'
        ? `${user.name} ${user.lastName}`
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        : user.email.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  };
  let formFields = initialValues;
  $: formFields = $formValues;
</script>

<div
  class="flex h-fit w-fit flex-col items-center justify-between gap-2 rounded-lg bg-white p-6"
>
  <h1 class="text-2xl font-bold text-dark-grey">
    {$t('dashboard.users.invite-users-title')}
  </h1>
  {#if tenant?.slackIntegrationEnabled}
    {#await fetchWorkspaceUsers()}
      <Loading loadingSize="h-20 w-20" />
    {:then workspaceUsers}
      {#if workspaceUsers}
        <p>
          {$t('dashboard.users.invite-users.description')}
        </p>

        <SearchUserInput
          searchByOptions={['name', 'email']}
          on:search={handleSearch}
        />
        <InviteUsersTable
          workspaceUsers={filteredUsers}
          handleInviteSlackUser={handleInviteSlackUser}
        />
      {:else}
        <p>{$t('dashboard.users.invite-users.not-found')}</p>
      {/if}
    {/await}
  {:else}
    <p class="pb-4 text-center" data-cy="slack-integration-not-enabled-title">
      {@html $t('dashboard.users.invite-users.integration-required')}
    </p>
    <p>
      {@html $t('dashboard.users.invite-users.slack-not-integrated')}
    </p>
  {/if}
  <p class="mt-4">
    {@html $t('dashboard.users.invite-users.email-invitation-description')}
  </p>
  <form
    use:form
    class="flex min-w-[24rem] max-w-[35rem] flex-col items-center justify-center gap-2"
  >
    {#each formFields.users as _user, index}
      <div class="flex w-full flex-row items-start gap-2">
        <Input
          disabled={$isSubmitting}
          styleClass="w-[20rem]"
          placeholder={$t('dashboard.users.invite-users.name-placeholder')}
          name={`users.${index}.name`}
          type="text"
          error={$errors?.users?.[index]?.name
            ? $errors?.users?.[index]?.name?.[0]
            : ''}
        />
        <Input
          disabled={$isSubmitting}
          styleClass="w-[20rem]"
          placeholder={$t('dashboard.users.invite-users.last-name-placeholder')}
          name={`users.${index}.lastName`}
          type="text"
          error={$errors?.users?.[index]?.lastName
            ? $errors?.users?.[index]?.lastName?.[0]
            : ''}
        />
        <Input
          disabled={$isSubmitting}
          styleClass="w-full"
          placeholder={$t('dashboard.users.invite-users.email-placeholder')}
          name={`users.${index}.email`}
          type="email"
          error={$errors?.users?.[index]?.email
            ? $errors?.users?.[index]?.email?.[0]
            : ''}
        />
        <div class="mt-1.5">
          <Button
            disabled={$isSubmitting}
            testId={`remove-user-fields-button-${index}`}
            rounded="rounded-md"
            on:click={() =>
              formFields.users.length > 1 && unsetField(`users.${index}`)}
            ><DashIcon /></Button
          >
        </div>
      </div>
    {/each}

    <div class="w-full">
      <Button
        disabled={$isSubmitting}
        testId="add-user-fields-button"
        rounded="rounded-md"
        on:click={() =>
          addField('users', { name: '', lastName: '', email: '' })}
        ><PlusIcon /></Button
      >
    </div>
    <Button
      on:click={handleSubmit}
      disabled={$isSubmitting}
      rounded="rounded-md"
      testId="send-invite-button"
    >
      {@html $t('dashboard.users.invite-users.email-invitation-btn')}
    </Button>
  </form>
</div>
