<script lang="ts">
  import type { ComponentProps } from 'svelte/internal';
  import type { IPaginatedQuery } from '@/interface/IPaginatedQuery';
  import { SessionStatus, type ISession } from '@/interface/ISession';
  import { UserRole } from '@/interface/IUser';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import Paginator from '@/lib/components/paginator/Paginator.svelte';
  import { sessionCountStore } from '@/lib/components/sidebar/session.count.store';
  import {
    handleCancelSession,
    handleEditSessionModal,
    handleFinishSession,
  } from '@/lib/components/tables/session-table/session-table-common-actions';
  import SessionTable from '@/lib/components/tables/session-table/SessionTable.svelte';
  import { getSessions } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  let query: IPaginatedQuery = {
    limit: 10,
    offset: 0,
  };

  let sessions: ISession[] | null = null;
  let count = 0;

  let isUser = $userStore?.role === UserRole.USER;
  const fetchSessions = async () => {
    const { sessions: newSessions, count: newCount } = await getSessions(query);
    $sessionCountStore = newCount;
    sessions = newSessions;
    count = newCount;
  };

  const canModifyCheck = (session: ISession) => {
    const isHost = session.host.id === $userStore?.id;
    const isAdmin =
      $userStore?.role === UserRole.ADMIN ||
      $userStore?.role === UserRole.SUPER_ADMIN;
    const sessionCanBeModified = session.status === SessionStatus.PENDING;
    return (isHost || isAdmin) && sessionCanBeModified;
  };

  $: query, fetchSessions();
  const actions: ComponentProps<SessionTable>['actions'] = isUser
    ? []
    : [
        {
          name: $t('dashboard.sessions.table-action-finish'),
          onAction: (session: ISession) =>
            handleFinishSession(session, fetchSessions),
          hideOnDisabled: true,
          disable: (session: ISession) => !canModifyCheck(session),
        },
        {
          name: $t('dashboard.teams.view.session-table-action-edit'),
          onAction: (session: ISession) =>
            handleEditSessionModal(session, fetchSessions),
          hideOnDisabled: true,
          disable: (session: ISession) => !canModifyCheck(session),
        },
        {
          name: $t('dashboard.teams.view.session-table-action-cancel'),
          onAction: (session: ISession) =>
            handleCancelSession(session, fetchSessions),
          hideOnDisabled: true,
          disable: (session: ISession) => !canModifyCheck(session),
        },
      ];
</script>

<div class="flex h-full w-full flex-col">
  <div
    class="flex h-16 w-full flex-row items-center justify-start border-b border-light-grey px-6"
  >
    <h1 class="mt-1 text-xl font-bold text-primary">
      {$t('dashboard.sessions.title')}
    </h1>
  </div>

  <div class="p-6">
    <div
      class="flex w-full flex-col gap-4 overflow-hidden rounded-lg border border-light-grey bg-white p-8"
    >
      {#if sessions === null}
        <div class="flex h-80 w-full grow items-center justify-center">
          <Loading loadingSize="h-32 w-32" />
        </div>
      {:else if sessions.length === 0}
        <span class="text-dark-grey"
          >{$t('dashboard.sessions.no-sessions')}</span
        >
      {:else if sessions.length !== 0}
        <SessionTable actions={actions} sessions={sessions} />
        <Paginator
          total={count}
          query={query}
          onChange={(newQuery) => {
            query = newQuery;
          }}
        />
      {/if}
    </div>
  </div>
</div>
